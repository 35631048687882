<template>
  <div class="page-auth">
    <div class="page-main">
      <div class="main-text">请上传您的身份证正反面照片</div>
      <div class="i-body">
        <div class="i-photo" @click="uploadeImg">
          <img
            class="i-photo-img"
            :src="
              imgFrontUrl
                ? 'data:image/jpg;base64,' + imgFrontUrl
                : identityFront
            "
          />
        </div>

        <div class="i-photo" @click="uploadBackImg">
          <img
            class="i-photo-img"
            :src="
              imgBackUrl ? 'data:image/jpg;base64,' + imgBackUrl : identityBack
            "
          />
        </div>
      </div>
      <div class="i-req">
        <div class="req-header">
          <i class="req-frist"></i>
          <span>拍摄照片要求</span>
          <i class="req-secord"></i>
        </div>
        <div class="req-mian">
          <div v-for="item in intro" :key="item.type" class="req-main-img">
            <img :src="item.img" />
          </div>
        </div>
      </div>
    </div>
    <div class="auth-btn" @click="authVerify">认证</div>
    <Modal
      :show="showback"
      @cancel="cancel"
      :showCancel="true"
      class="infrom-modal"
      :title="'信息确认'"
    >
      <div class="bank-line">
        <span class="bank-line-name">姓名</span>
        <input
          class="bank-input"
          :value="realName"
          @change="authChange($event)"
        />
      </div>
      <div class="bank-line">
        <span class="bank-line-name">身份证号码</span>
        <input
          class="bank-input"
          :value="idCardNumber"
          @change="IDChange($event)"
        />
      </div>
      <div class="bank-line">
        <span class="bank-line-name">身份证有效期</span>
        <input
          class="bank-input"
          :value="validDate"
          @change="dateChange($event)"
        />
      </div>
      <div class="bank-picture">
        <div class="pic-img">
          <img :src="'data:image/jpg;base64,' + imgFrontUrl" />
          <i className="m-shui"></i>
        </div>
        <div class="pic-img pic-c">
          <img :src="'data:image/jpg;base64,' + imgBackUrl" />
          <i className="m-shui"></i>
        </div>
      </div>
      <div class="bank-btn" @click="confirmOcr">确认</div>
    </Modal>
    <!-- 扫描身份证方式（从相册选取/直接扫描）身份证正面 -->
    <van-action-sheet
      v-model:show="showAction"
      :actions="actionPhoto"
      @select="onSelectcard"
    />
    <!-- 身份证反面 -->
    <van-action-sheet
      v-model:show="showActionBack"
      :actions="actionPhoto"
      @select="onSelectcardBack"
    />
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs, reactive } from "vue";
import APP from "@/utils/App";
import API from "../../server/api";
import Modal from "@/components/Modal/Modal.vue";
import { routerTag, EvalEvent, dealImage } from "../../utils/index";
import { Toast } from "vant";
import axios from "axios";
import { useStore } from "vuex";

export default {
  components: { Modal },

  setup() {
    const $store = useStore();
    const state = reactive({
      showback: false,
      identityFront: require("./img/cardface.png"), // 身份证正deletable: false,
      identityBack: require("./img/cardreserve.png"), // 身份证反
      imgFrontUrl: null, // 正
      imgBackUrl: null, // 反
      frontImgPath: null,
      reverseImgPath: null,
      intro: [
        {
          type: "http://supertest.jqtianxia.cn/static/imgO.png",
          img: "http://supertest.jqtianxia.cn/static/imgO.png",
          txt: "标准拍摄",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgS.png",
          img: "http://supertest.jqtianxia.cn/static/imgS.png",
          txt: "边框缺失",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgT.png",
          img: "http://supertest.jqtianxia.cn/static/imgT.png",
          txt: "照片模糊",
        },
        {
          type: "http://supertest.jqtianxia.cn/static/imgF.png",
          img: "http://supertest.jqtianxia.cn/static/imgF.png",
          txt: "闪光强烈",
        },
      ],
      fileName: "",
      fileKey: "",
      typeU: null,
      userInfo: {},
      realName: "", // 姓名
      idCardNumber: "", // 身份证
      validDate: "", // 有效期
      ocrInfo: null, // 正面数据新诺威尔
      ocrBackInfo: null, // 反面数据新诺威尔
      userId: "",
      showAction: false,
      showActionBack: false,
      actionPhoto: [
        {
          name: "从相册选取",
        },
        {
          name: "扫描身份证",
        },
      ],
    });

    const modalCheck = (e) => {
      state.showback = false;
    };
    const getNewFormDate = () => {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var srtDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (srtDate >= 1 && srtDate <= 9) {
        srtDate = "0" + srtDate;
      }
      var currentdate = year + month + srtDate;
      return currentdate;
    };
    //选取身份证方式选择（正面）
    const onSelectcard = (item) => {
      const fileKey = "LRD-CERT";
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      state.showAction = false;
      // Toast(item.name);
      if (item.name == "从相册选取") {
        if (!APP.BROWSER.android) {
          //IOS
          APP.iOS_PICKPHOTO(async (res) => {
            // console.log(res, "ios选择照片");
            if (res.code == 0) {
              if (res.response.images[0]) {
                state.imgFrontUrl = res.response.images[0];
                let responsesFrontss = await dealImage(
                  "data:image/jpg;base64," + res.response.images[0],
                  800
                );
                getOss(fileKey, responsesFrontss, "front");
              }
            }
          });
        } else {
          //安卓上传
          APP.PHOTO_CAMERA_MORE(async (res) => {
            console.log(res.response, "安卓选择照片");
            if (res.code == 0) {
              if (res.response.images[0]) {
                state.imgFrontUrl = res.response.images[0];
                let responsesFronts = await dealImage(
                  "data:image/jpg;base64," + res.response.images[0],
                  800
                );
                getOss(fileKey, responsesFronts, "front");
              }
            }
          });
        }
      } else {
        //扫描身份证
        APP.detectionFrontIDCard(async (res) => {
          console.log(res, "安卓选择照片");
          const { image } = res;
          if (image) {
            state.imgFrontUrl = image;
            let responsesFront = await dealImage(
              "data:image/jpg;base64," + image,
              800
            );
            getOss(fileKey, responsesFront, "front");
          }
        });
      }
    };
    //使用压缩
    // function printing(base64,type) {
    //   console.log(type,'type');
    //   const fileKey = "LRD-CERT";
    //   getOss(fileKey, base64, type);
    //   console.log("压缩后", base64.length / 1024);
    // }
    //选取身份证方式选择（反面）
    const onSelectcardBack = (item) => {
      const fileKey = "LRD-CERT";
      state.showActionBack = false;
      // Toast(item.name);
      if (item.name == "从相册选取") {
        if (!APP.BROWSER.android) {
          //IOS
          APP.iOS_PICKPHOTO(async (res) => {
            // console.log(res, "ios选择照片");
            if (res.code == 0) {
              state.imgBackUrl = res.response.images[0];
              let response = await dealImage(
                "data:image/jpg;base64," + res.response.images[0],
                800
              );
              getOss(fileKey, response, "back");
            }
          });
        } else {
          //安卓上传
          APP.PHOTO_CAMERA_MORE(async (res) => {
            // console.log(res, "安卓选择照片");
            if (res.code == 0) {
              state.imgBackUrl = res.response.images[0];
              let responses = await dealImage(
                "data:image/jpg;base64," + res.response.images[0],
                800
              );
              getOss(fileKey, responses, "back");
            }
          });
        }
      } else {
        //扫描身份证
        APP.detectionBackIDCard(async (res) => {
          console.log(res,999);
          state.imgBackUrl = res.image;
          let responsess = await dealImage(
            "data:image/jpg;base64," + res.image,
            800
          );
          getOss(fileKey, responsess, "back");
        });
      }
    };
    //    身份证正面
    const uploadeImg = () => {
      state.showAction = true;
    };
    //    身份证背面
    const uploadBackImg = () => {
      state.showActionBack = true;
    };
    // 获取oss路径
    const getOss = async (fileKey, file, type) => {
      const result = await API.uploadOss({
        fileName: "jpg",
        fileType: fileKey,
      });
      const { host, accessId, dir, policy, signature } = result;
      const data = new FormData();
      data.append("key", dir);
      data.append("OSSAccessKeyId", accessId);
      data.append("policy", policy);
      data.append("Signature", signature);
      data.append("success_action_status", "200");
      data.append("file", base64ToFile(file, "img"));
      const baseUrl = host;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      axios({
        url: baseUrl,
        method: "post",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          // console.log(res.status,typeof(res.status),8555)
          if (res.status === 200) {
            Toast.success("图片上传成功", 1.2);
            const imgOssUrl = `${baseUrl}${dir}`;
            // console.log(imgOssUrl, "9090909");
            // const joinUrl =
            //   "image/auto-orient,1/resize,m_fixed,w_3000,h_3500/quality,q_50";
            if (type === "front") {
              state.frontImgPath = imgOssUrl;
            }
            if (type === "back") {
              state.reverseImgPath = imgOssUrl;
            }
          }
        })
        .catch((e) => {
          Toast.fail("图片上传失败，请刷新页面重试", 1.2);
        });
    };

    const base64ToFile = (dataUrl, name) => {
      const arr = dataUrl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], name + ".jpg", { type: "image/jpg" });
    };

    // 点击认证
    const authVerify = async () => {
      // console.log(111);
      if (state.imgFrontUrl == null) {
        Toast("请上传身份证正面照");
        return false;
      }
      if (state.imgBackUrl == null) {
        Toast("请上传身份证反面照");
        return false;
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const dataPath = {};
      dataPath.frontImg = state.frontImgPath;
      dataPath.backImg = state.reverseImgPath;
      dataPath.userId = state.userId;
      await authPath(dataPath);
      Toast.clear();
    };
    const authPath = async (dataPath) => {
      console.log(dataPath, 84);
      try {
        // 请求成功之后 再显示弹框
        console.log(dataPath, 85);
        const result = await API.updateAuth(dataPath);
        if (result.code == 200) {
          Toast.success(result.message, 1.2);
          setTimeout(function () {
            APP.BACK(-1);
          }, 1000);
        } else {
          Toast.fail(result.message, 1.2);
        }
        console.log(result, "9090909090900");
        // state.showback = true;
        // state.userInfo = result;
        // state.realName = result.realName;
        // state.idCardNumber = result.idCardNumber;
        // state.validDate = result.validDate;
        // localStorage.setItem("authName", state.realName);
      } catch (error) {
        console.log(error);
      }
    };
    const cancel = (show) => {
      state.showback = show;
    };
    const authChange = (e) => {
      state.realName = e.target.value;
      // console.log(e.target.value,12);
    };
    const IDChange = (e) => {
      state.idCardNumber = e.target.value;
      console.log(state.idCardNumber, 997);
    };
    const dateChange = (e) => {
      state.validDate = e.target.value;
    };

    // 点击确定
    const confirmOcr = () => {
      authSub();
    };
    // 实名认证提交 face++
    const authSub = async () => {
      if (state.realName == "") {
        Toast("请填写姓名");
        return false;
      }
      if (state.idCardNumber == "") {
        Toast("请填写身份证号码");
        return false;
      }
      if (state.validDate == "") {
        Toast("请填写身份证有效期");
        return false;
      }
      const result = await API.authSubmit({
        realName: state.realName,
        address: state.userInfo.address,
        validDate: state.userInfo.validDate,
        issuedBy: state.userInfo.issueBy,
        idCard: state.idCardNumber,
        race: state.userInfo.race,
        userId: state.userId,
        frontImgPath: state.frontImgPath,
        reverseImgPath: state.reverseImgPath,
        overdueDate: state.validDate,
      });
      if (result) {
        APP.BACK(-1);
      }
    };
    onMounted(() => {
      localStorage.removeItem("authName");
      state.userId = localStorage.getItem("userId");
      APP.SET_TITLE("实名认证");
    });

    return {
      ...toRefs(state),
      authVerify,
      cancel,
      modalCheck,
      authSub,
      uploadeImg,
      uploadBackImg,
      confirmOcr,
      authChange,
      IDChange,
      dateChange,
      authPath,
      onSelectcard,
      onSelectcardBack,
    };
  },
};
</script>
